import { AppRouteProps } from "@mzara/component";
import React from "react";
import { ProjectDayOffViewsSummaryRoutes } from "./views/summary/ProjectDayOffViewsSummaryRoutes";
import { ProjectDayOffViewsDemandRoutes } from "./views/demand/ProjectDayOffViewsDemandRoutes";
import { ProjectDayOffViewsCalendarRoutes } from "./views/calendar/ProjectDayOffViewsCalendarRoutes";
const ProjectDayOffContainer = React.lazy(
    () => import("./container/ProjectDayOffContainer")
);

export const ProjectDayOffRoutes: Array<AppRouteProps> = [
    {
        path: "dayoff",
        title: "Harea.Vacation.DayOff",
        element: <ProjectDayOffContainer />,
        roles: ["APPOINTMENT"],
        children: [
            ...ProjectDayOffViewsDemandRoutes,
            ...ProjectDayOffViewsSummaryRoutes,
            ...ProjectDayOffViewsCalendarRoutes,
        ],
    },
];
