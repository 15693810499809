import { AppRouteProps } from "@mzara/component";
import React from "react";
const ProjectDayOffViewsCreateContainer = React.lazy(
    () => import("./container/ProjectDayOffViewsCreateContainer")
);

export const ProjectDayOffViewsCreateRoutes: Array<AppRouteProps> = [
    {
        path: "create",
        title: "Harea.Vacation.DayOff.Creation",
        element: <ProjectDayOffViewsCreateContainer />,
        roles: ["APPOINTMENT"],
    },
];
