import {
    GenericSuspense,
    IconButton,
    RightSidebar,
    useUrlParamsValue
} from "@mzara/component";
import { VacationContainer } from "@mzara/project";
import { calendarOpenState } from "components/layout/AdminNavBar";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import BarLoader from 'react-spinners/BarLoader';
import { useRecoilState } from "recoil";
import { AdminLeftSideBar } from "./AdminLeftSideBar";
import { AdminNavBar as NavBar } from "./AdminNavBar";
import { NotificationDialog } from "@mzara/notification";

const AdminLayout = () => {
    const [openSidebar, setOpenSidebar] = useState(true);
    const [openCalendar] = useRecoilState(calendarOpenState);

    const toogleSidebar = () => {
        setOpenSidebar(!openSidebar);
    };

    const urlParams = useUrlParamsValue();
    const hideSidebar = urlParams.create || urlParams.eventId;

    return (
        <>
            <div className="admin-layout flex w-full">
                <div
                    onClick={() => setOpenSidebar(false)}
                    className={`fixed top-0 bottom-0 left-0 right-0 sm:w-0 bg-black/70 ${openSidebar ? "opacity-100 z-10" : "opacity-0 -z-10"
                        } transition-all duration-1000 ease-in-out`}
                >
                    <IconButton
                        icon={"fa-solid fa-xmark"}
                        className={"absolute top-2 right-4 text-lg text-white sm:hidden"}
                        onClick={() => setOpenSidebar(false)}
                    />
                </div>
                <AdminLeftSideBar open={openSidebar} />

                <main className="w-full transition-all duration-1000 ease-in-out flex-1 flex flex-col !bg-body-background">
                    <NavBar onToggleClick={() => toogleSidebar()} />
                    <GenericSuspense
                        fallback={
                            <BarLoader className='main-spinner-bar-loader shadow-sm' width="100%" />
                        }
                    >
                        <div className="flex items-start w-full p-0 parent-layout">
                            <Outlet />
                        </div>
                    </GenericSuspense>
                </main>
            </div>

            <NotificationDialog />
        </>
    );
};

export default AdminLayout;
