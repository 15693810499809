import {
    Dialog,
    GenericSuspense,
    Menu,
    useSetCurrentDocumentTitle,
    useTranslationRoute,
    useUrlParamsValueToString,
} from "@mzara/component";
import { useCallback, useEffect } from "react";
import { TAppointment } from "@mzara/graphql";
import _ from "lodash";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useAppointmentQuery } from "hooks/useAppointmentQuery";
import { AppointmentDetailsLoader } from "../components/AppointmentDetailsLoader";

const ProjectCalendarDetailsAppointmentContainer = () => {

    const { id } = useParams()
    const { data } = useAppointmentQuery(parseInt(id));
    useSetCurrentDocumentTitle('AppointmentTitle', data?.title)
    const navigate = useNavigate()
    const urlParamsToString = useUrlParamsValueToString()

    const handleClose = useCallback(() => {
        navigate(`..?${urlParamsToString}`)
    }, [id, urlParamsToString])

    return (
        <>
            <Dialog
                open={id !== undefined}
                className="max-w-xl"
                title={data?.title}
                onClose={handleClose}>
                <AppointmentDetailsLoader onDeleted={handleClose} />
            </Dialog>
        </>
    );
};

export default ProjectCalendarDetailsAppointmentContainer;

export type VacationDialogStateProps = {
    id?: number;
    isKanban?: boolean;
    onClose?: () => void;
    onSubmited?: (data?: TAppointment) => void;
    onDeleted?: (data?: Record<string, any>) => void;
};
