import { useNavigate, useParams } from "react-router-dom";
import { useKanbanCardDetailsQuery } from "../../../hooks/useKanbanCardDetailsQuery";
import { useUrlParamsValueToString } from "@mzara/component";
import { KanbanCardDetailsMenu } from "../components/KanbanCardDetailsMenu";
import { AppointmentDetails } from "components/AppointmentDetails";

export const KanbanCardDetails = () => {

    const { id, cardNum } = useParams()
    const { data, invalidateQuery } = useKanbanCardDetailsQuery(parseInt(id), parseInt(cardNum), true);
    const urlParamsToString = useUrlParamsValueToString()
    const navigate = useNavigate()

    return (
        <AppointmentDetails
            {...data}
            toolbox={(
                <KanbanCardDetailsMenu
                    id={data.id}
                    boardId={parseInt(id)}
                    num={data.num}
                    onUpdated={() => invalidateQuery()}
                    onDeleted={() => navigate(`../..?${urlParamsToString}`)}
                />
            )}
        />
    )
}
