import {
    Box,
    Button,
    ControlList,
    Dialog,
    GenericSuspense,
    IconButton,
    List,
    Metadata,
    RightSidebar,
    useRuntimeEnv,
    useTranslationRoute,
    useTranslations,
    useUrlParamsValueToString,
} from "@mzara/component";
import { TAppointment } from "@mzara/graphql";
import { AppointmentsQuery } from "queries/AppointmentsQuery";
import { Link, Outlet, useNavigate } from "react-router-dom";
import PlanningListItem from "../components/PlanningListItem";
import { useState } from "react";
import { useWorkHourControl } from "../hooks/useWorkHourControl";
import { useOrganisationWorkHourQuery } from "../hooks/useOrganisationWorkHourQuery";

export const PlanningListContainer = () => {
    const [
        ADD,
        WORK_HOUR_CARD_TITLE,
        TIMETABLE,
        PAUSE,
        WORK_HOUR_DIALOG_TITLE
    ] = useTranslations(i18n);

    return (
        <>


            <List
                search={false}
                pagination={false}
                selection={false}
                GQLRequest={AppointmentsQuery}
                isUrlParamsState={false}
                defaultFilter={{
                    data: {
                        invitations: {
                            user: {},
                        },
                        labels: {},
                        board: {},
                        type_among: ["CONGE", "PLANNING", "ABSENCE"],
                    },
                    sort: [{ sort: 'DESC', value: 'updatedAt' }]
                }}
                onRenderRow={(row: TAppointment) => (
                    <PlanningListItem item={row} />
                )}
            />

            <GenericSuspense>
                <Outlet />
            </GenericSuspense>


        </>
    );
};

const i18n = [
    "Harea.Planning.Sidebar.Add.Button.Label",
    "Harea.Planning.Edit.WorkHour.Card.Title",
    "std_timetable",
    "std_pause",
    "Harea.Planning.Edit.WorkHour.Dialog.Title",
];
