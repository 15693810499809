
import { Button, ControlList, Dialog, Dropdown, IconButton, Menu, MenuItemProps, useGraphqlDelete, useGraphqlMutation, useTranslations, useUrlParamsValue } from "@mzara/component";
import { useAppointmentDetailsMenu } from "../hooks/useAppointmentDetailsMenu"
import { useCallback, useMemo, useState } from "react";
import moment from "moment";
import { OVERRIDE_APPOINTMENT_MUTATION } from "../views/edit/query/OverrideAppointmentMutation";
import { AppointmentDetailsToolboxProps } from "./AppointmentDetailsToolbox";

export const AppointmentDetailsMenu = (props: AppointmentDetailsToolboxProps) => {

    const [
        DELETE_CONFIRM,
        DELETE_ONE_LABEL,
        DELETE_ALL_LABEL,
        EDIT_CONFIRM,
        CONFIRMATION_TITLE,
        CANCEL_BTN,
        OK_BTN,
    ] = useTranslations(i18n);

    const [open, setOpen] = useState<boolean>();
    const { eventDate } = useUrlParamsValue();
    const items = useAppointmentDetailsMenu(props.id)
    const [anchor, setAnchor] = useState<HTMLButtonElement>()

    const deleteMutation = useGraphqlDelete("appointment");
    const deleteOverrideMutation = useGraphqlMutation(OVERRIDE_APPOINTMENT_MUTATION)


    const handleDeleteAllConfirmed = () => {
        deleteMutation.mutate(
            { ids: [props.id] },
            {
                onSuccess: (data) => {
                    setOpen(false);
                    props.onDeleted?.()
                },
            }
        );
    };

    const handleDeleteOneConfirmed = () => {
        deleteOverrideMutation.mutate({
            data: {
                override: { id: props?.id.toString() },
                overrideType: "DELETED",
                dateBegin: eventDate ? moment(eventDate).format() : moment(props.dateBegin).format(),
                dateEnd: eventDate ? moment(eventDate).format() : moment(props.dateEnd).format()
            },
        },
            {
                onSuccess: () => {
                    setOpen(false);
                    props.onDeleted?.()
                }
            });
    }

    const handleMenuClick = useCallback(async (menu: MenuItemProps) => {
        if (menu.ke === 'delete') {
            setOpen(true)
        }
    }, [])


    const handleCloseDialog = () => {
        setOpen(false);
    };

    return (
        <>
            <div className="flex gap-2">
                <IconButton
                    className="ml-auto mr-2"
                    icon="fa-solid fa-ellipsis"
                    onClick={(e) => setAnchor(e.currentTarget)}
                />
            </div>

            <Dropdown anchorEl={anchor} onClose={() => setAnchor(undefined)}>
                <Menu
                    onClick={handleMenuClick}
                    items={items}
                />
            </Dropdown>

            {
                props?.repetition && (
                    <Dialog
                        open={open}
                        onClose={handleCloseDialog}
                        title={CONFIRMATION_TITLE}
                    >
                        <div className="flex flex-col">
                            <p>
                                {DELETE_CONFIRM}
                            </p>
                            <div className="flex justify-between mt-4">
                                <Button
                                    className="btn-default"
                                    onClick={handleDeleteOneConfirmed}
                                    isSubmit={deleteOverrideMutation.isLoading}
                                >
                                    {DELETE_ONE_LABEL}
                                </Button>
                                <Button
                                    className="btn-danger"
                                    onClick={handleDeleteAllConfirmed}
                                    isSubmit={deleteMutation.isLoading}
                                >
                                    {DELETE_ALL_LABEL}
                                </Button>
                            </div>
                        </div>
                    </Dialog>
                )
            }

            {
                !props?.repetition && (
                    <Dialog
                        open={open}
                        onClose={handleCloseDialog}
                        btnOk={{
                            className: "btn-danger",
                            label: OK_BTN,
                            isSubmit: deleteMutation.isLoading,
                        }}
                        btnCancel={{ label: CANCEL_BTN }}
                        title={CONFIRMATION_TITLE}
                        onCancel={handleCloseDialog}
                        onConfirm={handleDeleteAllConfirmed}
                    >
                        <span>{DELETE_CONFIRM}</span>
                    </Dialog>
                )
            }

        </>
    )
}

const i18n = [
    "Harea.Vacation.Edit.Delete.Confirm.Message",
    "Harea.Calendar.Edit.DeleteOne.Button.Label",
    "Harea.Calendar.Edit.DeleteAll.Button.Label",
    "Harea.Vacation.Edit.Repetition.Confirm.Message",
    "std_confirmation",
    "std_cancel",
    "std_confirm",
];
