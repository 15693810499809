import { GraphQLQueryClass } from "@mzara/component";
import { TAppointment } from "@mzara/graphql";

export class AppointmentQuery implements GraphQLQueryClass<TAppointment, TResponse>{
    public queryKey: string = 'App.bo.appointment';
    public gql: string = `
    query AppointmentQuery($id:Float){
        appointment(filter:{id:$id}){
           id title description dateBegin dateEnd hourBegin hourEnd type isDeletable isEditable 
           user{
            id fullName firstName lastName profilePicture{
                id name
            }
           }
            invitations {
                isRevocked
                isValidated
                ke
                user {
                    id
                    fullName
                }
            }
            thread { id }
            repetition
           userCreator{
            id fullName firstName lastName email profilePicture{
                id name
            }
           }
        }
      }
    `;
    variables?: any;
    constructor(variables?: any) {
        this.variables = variables;
    }
    mapFn(data: TResponse): TAppointment {
        return data.appointment
    };
}

export type TResponse = {
    appointment: TAppointment
}