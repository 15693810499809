import { Avatar, MenuItemProps, useFileUrl, useRuntimeEnv, useRuntimeEnvRolesIncludes, useTranslationRoute, useTranslations, useUserProfilePicture } from "@mzara/component";
import { useMemo } from "react";

export const useLeftSideBarMenuParameters = () => {

    const [
        PARAMETERS,
        ORGANISATIONS,
        USERS,
        LOGOUT,
    ] = useTranslations(i18n)
    const translationRoute = useTranslationRoute();

    const { runtimeEnv } = useRuntimeEnv();

    const roleIncludes = useRuntimeEnvRolesIncludes(true);
    const fileUrl = useFileUrl();
    const profilePictureUrl = useUserProfilePicture()

    const organisation: MenuItemProps = useMemo(
        () => {
            const canAccessList = roleIncludes("ORGANISATION_LICENSE")
            return ({
                label: canAccessList
                    ? ORGANISATIONS
                    : runtimeEnv?.organisation?.designation,
                link: translationRoute(
                    `admin/user/organisation${
                        canAccessList
                            ? ""
                            : "/" + runtimeEnv.organisation?.id
                    }`
                ),
                startIcon: "fa-solid fa-building",
                roles: canAccessList
                    ? ["ORGANISATION"]
                    : undefined,
            })
        },
        [runtimeEnv, roleIncludes]
    );

    return useMemo(() => ({
        ke: "parameters",
        label: PARAMETERS,
        className: "menu-item-category",
        items: [
            {
                label: runtimeEnv?.user.fullName,
                startIcon: (
                    <Avatar
                        size="xs"
                        src={profilePictureUrl(runtimeEnv?.user?.id)}
                        fullName={runtimeEnv.user?.fullName}
                    />
                ),
                link: translationRoute(
                    `admin/user/${runtimeEnv?.user.id}/about`
                ),
                isConnected: true,
                className: "username-menu",
            },
            organisation,
            roleIncludes("ORGANISATION_LICENSE") ? {
                label: USERS,
                startIcon: "fa-solid fa-users",
                link: translationRoute("admin/user/list"),
                roles: ["ORGANISATION_LICENSE"],
            } : undefined,
            {
                label: LOGOUT,
                startIcon: "fa-solid fa-right-to-bracket",
                link: translationRoute("logout"),
            },
        ],
    }), [])
}

const i18n = [
    'Harea.Admin.LeftSideBar.Menu.Parameters.Label',
    'Harea.Admin.LeftSideBar.Menu.Parameters.Organisations.Label',
    'Harea.Admin.LeftSideBar.Menu.Parameters.Users.Label',
    'Harea.Admin.LeftSideBar.Menu.Parameters.Logout.Label',
]
