import { AppRouteProps } from "@mzara/component";
import React from "react";
const ProjectDayOffViewsDetailContainer = React.lazy(
    () => import("./container/ProjectDayOffViewsDetailContainer")
);

export const ProjectDayOffViewsDetailRoutes: Array<AppRouteProps> = [
    {
        path: ":id",
        title: "Harea.Vacation.DayOff.Detail",
        element: <ProjectDayOffViewsDetailContainer />,
        roles: ["APPOINTMENT"],
    },
];
