import { Box, Button, useTranslationRoute, useTranslations } from "@mzara/component";
import { Link } from "react-router-dom";

const Congratulation = () => {
    const [
        TITLE,
        DESCRIPTION,
        CTA
    ] = useTranslations(i18n)
    const tr = useTranslationRoute()
    return (
        <Box className="w-full flex flex-col items-center gap-5">
            <h2 className="text-3xl">{TITLE}</h2>

            <p dangerouslySetInnerHTML={{ __html: DESCRIPTION.replaceAll('\n', '<br/>') }} />

            <Link to={tr('')} className="mt-8">
                <Button
                    className="btn-primary"
                    label={CTA}
                />
            </Link>
        </Box>
    );
};


const i18n = [
    'Harea.Evaluation.Client.Finished.Title',
    'Harea.Evaluation.Client.Finished.Description',
    'Harea.Evaluation.Client.Finished.CallToAction.Label',
]

export default Congratulation;
