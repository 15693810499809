import { AppRouteProps } from "@mzara/component";
import React from "react";

const PlanningCreateContainer = React.lazy(
    () => import("./container/PlanningCreateContainer")
);

export const ProjectPlanningCreateRoutes: Array<AppRouteProps> = [
    {
        path: "-1",
        title: "Harea.Calendar.Planning.Create",
        element: <PlanningCreateContainer />,
    },
];
