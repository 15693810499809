import { Button, Chip, ControlSkeleton, DateSpan, GenericSuspense, MDEditor, Metadata, useFileUrl, useTranslations, useUrlParamsValueToString } from "@mzara/component"
import { TAppointment } from "@mzara/graphql"
import { Thread } from "@mzara/thread"
import { Link, Outlet } from "react-router-dom"
import { ReactNode, useMemo } from "react"
import { FileItem } from "views/kanban/views/details/views/details/views/details/components/FileItem"

export const AppointmentDetails = (props: Props) => {
    const [
        CREATOR,
        STATUS,
        CLOSED,
        OPENED,
        CREATED_AT,
        DATE_END,
        LABELS,
        USERS,
        DESCRIPTION,
        ATTACHMENT,
        METADATA,
        ARCHIVED,
        EDIT
    ] = useTranslations(i18n)
    const urlParamsToString = useUrlParamsValueToString()
    const fileUrl = useFileUrl()


    const ticketStatus = useMemo(() => {
        if (props?.dateArchive) {
            return ARCHIVED
        }

        return props?.isClosed ? CLOSED : OPENED
    }, [props])

    return (
        <div className="flex flex-col gap-10">
            <div className="flex flex-col gap-4">
                <div className="flex justify-between flex-wrap">
                    <h3>{METADATA}</h3>
                    {
                        props.isEditable &&
                        <div className="flex gap-2">
                            <Link to={`edit?${urlParamsToString}`}>
                                <Button
                                    className="btn-default"
                                    startIcon="fa-pen"
                                    label={EDIT}
                                />
                            </Link>
                            {props.toolbox}
                        </div>
                    }
                </div>
                <div className="flex gap-4 flex-wrap">
                    <Metadata
                        label={CREATOR}
                        value={(
                            <Link to={`user/${props?.userCreator.id}`}>
                                <Chip
                                    userId={props?.userCreator?.id}
                                    label={props?.userCreator?.fullName}
                                />
                            </Link>
                        )}
                    />
                    <Metadata
                        label={STATUS}
                        value={(
                            <Chip
                                className={`${props.isClosed ? 'bg-danger text-white' : 'bg-default'}`}
                                label={ticketStatus}
                            />
                        )}
                    />
                </div>

                <div className="flex gap-4 flex-wrap">
                    {
                        props.invitations?.length > 0 &&
                        <Metadata
                            label={USERS}
                            className="flex-col items-start"
                            value={(
                                <div className="flex gap-2 flex-wrap">
                                    {
                                        props.invitations.map((item) => (
                                            <Link to={`user/${item?.user?.id}`}>
                                                <Chip
                                                    userId={item?.user?.id}
                                                    label={item?.user?.fullName}
                                                />
                                            </Link>
                                        ))
                                    }
                                </div>
                            )}
                        />
                    }


                    {
                        props.labels?.length > 0 &&
                        <Metadata
                            label={LABELS}
                            className="flex-col items-start"
                            value={(
                                <div className="flex gap-2 flex-wrap">
                                    {
                                        props.labels?.map((item, index) => (
                                            <Chip
                                                key={index}
                                                className="bg-white px-2 py-0 text-xs"
                                                label={item.value}
                                                color={item.color}
                                            />
                                        ))
                                    }
                                </div>
                            )}
                        />
                    }
                </div>

                <div className="flex gap-4">
                    {
                        props.createdAt &&
                        <Metadata
                            icon="fa-clock"
                            value={(
                                <DateSpan value={props.createdAt} />
                            )}
                            title={CREATED_AT}
                        />
                    }
                    {
                        props.dateBegin &&
                        <Chip
                            startIcon="fa-clock"
                            className="bg-default"
                            label={
                                <DateSpan value={props.dateBegin} format="DD-MM-YYYY HH:mm" />
                            }
                            title={DATE_END}
                        />
                    }
                    {
                        props.dateEnd &&
                        <Chip
                            startIcon="fa-clock"
                            className="bg-danger text-white"
                            label={
                                <DateSpan value={props.dateEnd} format="DD-MM-YYYY HH:mm" />
                            }
                            title={DATE_END}
                        />
                    }
                </div>

                {
                    props.cover &&
                    <img className="w-full h-60 object-cover" src={fileUrl(props.cover?.id)} />
                }

            </div>

            <div className="flex flex-col gap-2">
                <h3>{DESCRIPTION}</h3>

                <GenericSuspense fallback={<ControlSkeleton />}>
                    <MDEditor readonly value={props.description ?? ''} />
                </GenericSuspense>
            </div>

            {
                props.files?.length > 0 &&
                <div className="flex flex-col gap-2">
                    <h3>{ATTACHMENT}</h3>

                    <div className="flex gap-4 flex-wrap">
                        {
                            props.files?.map((item, index) => (
                                <FileItem {...item} key={index} />
                            ))
                        }
                    </div>
                </div>
            }

            {
                props?.thread && (
                    <Thread
                        id={props?.thread?.id}
                        className="p-0 shadow-none"
                        shortenButton
                    />
                )
            }
            <GenericSuspense>
                <Outlet />
            </GenericSuspense>
        </div>
    )
}

type Props = Partial<TAppointment> & {
    toolbox?: ReactNode
}

const i18n = [
    'std_creator',
    'std_status',
    'std_closed',
    'std_opened',
    'std_created_at',
    'std_date_end',
    'Harea.Vacation.Kanban.Labels.Label',
    'Harea.Vacation.Kanban.Users.Label',
    'Harea.Vacation.Kanban.Description.Label',
    "std_attachment",
    "std_metadata",
    "std_archived",
    'std_edit'
]

