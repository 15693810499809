import { AppRouteProps } from "@mzara/component";
import React from "react";
import { ProjectDayOffViewsCreateRoutes } from "./views/create/ProjectDayOffCreateRoutes";
import { ProjectDayOffViewsDetailRoutes } from "./views/detail/ProjectDayOffViewsDetailRoutes";
const ProjectDayOffViewsDemandContainer = React.lazy(
    () => import("./container/ProjectDayOffViewsDemandContainer")
);

export const ProjectDayOffViewsDemandRoutes: Array<AppRouteProps> = [
    {
        path: "demand",
        title: "Harea.Vacation.DayOff.Request",
        element: <ProjectDayOffViewsDemandContainer />,
        roles: ["APPOINTMENT"],
        children: [
            ...ProjectDayOffViewsCreateRoutes,
            ...ProjectDayOffViewsDetailRoutes
        ]
    },
];
