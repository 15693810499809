import { AppRouteProps } from "@mzara/component";
import React from "react";
import { ProjectKanbanDetailsBoardRoutes } from "./views/board/ProjectKanbanDetailsBoardRoutes";
import { ProjectKanbanDetailsGanttRoutes } from "./views/gantt/ProjectKanbanDetailsGanttRoutes";


const ProjectKanbanDetailsContainer = React.lazy(() => import("./container/ProjectKanbanDetailsContainer"));

export const ProjectKanbanDetailsRoutes: Array<AppRouteProps> = [
    {
        path: ":id",
        title: "{{ProjectKanbanDetailsTitle}}",
        element: <ProjectKanbanDetailsContainer />,
        roles: ["APPOINTMENT"],
        children: [
            ...ProjectKanbanDetailsBoardRoutes,
            ...ProjectKanbanDetailsGanttRoutes,
        ]
    },
];
