import { AppRouteProps } from "@mzara/component";
import React from "react";
import { ProjectPlanningCreateRoutes } from "./views/create/PlanningCreateRoutes";
import { ProjectPlanningDetailRoutes } from "./views/detail/PlanningDetailRoutes";
import { PlanningListContainer } from "./container/PlanningListContainer";


export const CalendarPlanningListRoutes: Array<AppRouteProps> = [
    {
        path: "",
        title: "Harea.Vacation.Planning.Edit",
        element: (
            <PlanningListContainer />
        ),
        roles: ["APPOINTMENT"],
        children: [
            ...ProjectPlanningCreateRoutes,
            ...ProjectPlanningDetailRoutes
        ]
    },
];
