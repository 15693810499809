import { AppRouteProps } from "@mzara/component";
import React from "react";

const ProjectCalendarEditAppointmentContainer = React.lazy(() => import("./container/ProjectCalendarEditAppointmentContainer"));

export const ProjectCalendarEditRoutes: Array<AppRouteProps> = [
    {
        path: "edit",
        title: "Harea.Vacation.Calendar",
        element: <ProjectCalendarEditAppointmentContainer />,
        roles: ["APPOINTMENT"]
    },
];
