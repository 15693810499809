import { AppRouteProps } from "@mzara/component";
import React from "react";

const ProjectCalendarCreateAppointmentContainer = React.lazy(() => import("./container/ProjectCalendarCreateAppointmentContainer"));

export const ProjectCalendarCreateRoutes: Array<AppRouteProps> = [
    {
        path: "-1",
        title: "Harea.Vacation.Calendar",
        element: <ProjectCalendarCreateAppointmentContainer />,
        roles: ["APPOINTMENT"]
    },
];
