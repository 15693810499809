import {
    Avatar,
    Menu,
    MenuItemProps,
    useBreakpoints,
    useFileUrl,
    useRuntimeEnv,
    useRuntimeEnvRolesIncludes,
    useTranslation,
    useTranslationRoute,
    useUserProfilePicture,
} from "@mzara/component";
import { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import pkg from "../../../package.json";
import { useLeftSideBarMenu } from "./hooks/useLeftSideBarMenu";
import { usePremiumRoles } from "./hooks/usePremiumRoles";
import { useFreeRoles } from "./hooks/useFreeRoles";
import _ from "lodash";

export const AdminLeftSideBar = (props: AdminLeftSideBarProps) => {

    const profilePictureUrl = useUserProfilePicture()
    const { runtimeEnv } = useRuntimeEnv();
    const roleIncludes = useRuntimeEnvRolesIncludes()
    const url = useTranslationRoute();
    const t = useTranslation();
    const menuItems = useLeftSideBarMenu();
    const { md } = useBreakpoints();
    const freeRoles = useFreeRoles();
    const premiumRoles = usePremiumRoles();

    const isSubsetOfFreeRoles = useCallback(
        (menu: MenuItemProps): boolean =>
            menu.roles?.every((role: string) => freeRoles?.includes(role)),
        [freeRoles]
    );
    
    const transformedMenuItems = useMemo(() => {
        return menuItems.map((menu) => {
            return {
                ...menu,
                items: menu.items?.filter((item) => _.isEmpty(item?.roles) || !!item?.roles?.every((role: string) => roleIncludes(role)))
            }
        }).filter((item) => !_.isEmpty(item.items))
    }, [premiumRoles]);

    return (
        <aside
            className={`
                w-[240px]
                overflow-y-auto fixed sm:sticky sm:top-0 sm:bottom-0 h-screen overflow-x-hidden sidebar-left py-4 z-20 
                ${!props.open ? "ml-[240px] md:ml-0 md:w-[60px]" : "ml-0 p-0"} 
                transition-all duration-[250ms] ease-in-out
            `}
        >
            <Link to="/">
                <img
                    src={"/assets/img/logo.png"}
                    alt="Logo Harea"
                    className={`
                        absolute top-4 z-20
                        transition-all
                        ${!props.open ? "left-1 h-6" : " left-4 h-16"}
                    `}
                />
            </Link>
            <div
                className={`
                flex items-center flex-col justify-center py-10 text-xs tracking-wider gap-2
                mt-12 mb-4
                ${!props.open ? "opacity-0 h-8" : ""} 
                `}
            >
                <Link to={url(`user/${runtimeEnv.user?.id}`)}>
                    <Avatar
                        src={profilePictureUrl(runtimeEnv?.user?.id!)}
                        fullName={runtimeEnv?.user?.fullName}
                        size="lg"
                    />
                </Link>
                <div className="flex flex-col gap-2 justify-center items-center">
                    <Link to={url(`user/${runtimeEnv.user?.id}`)}>
                        <h6 className="text-center text-bold">
                            {runtimeEnv.user.fullName}
                        </h6>
                    </Link>
                    <Link
                        to={url(`organisation/${runtimeEnv.organisation?.id}`)}
                    >
                        <span className="text-[#555]">
                            {runtimeEnv.organisation?.designation}
                        </span>
                    </Link>
                </div>
            </div>
            <div>
                <Menu
                    items={transformedMenuItems}
                    onlyStartIcon={!md && !props.open}
                    disabledFn={(menu) => !isSubsetOfFreeRoles(menu)}
                />
            </div>
            <div className="mt-4 px-4 text-[12px] text-gray text-center">
                <a href="/">
                    {t(`Generic.de.${process.env.REACT_APP_NAME}`)}-
                    {pkg.version}
                </a>{" "}
                © {new Date().getFullYear()}
            </div>
        </aside>
    );
};

type AdminLeftSideBarProps = {
    open: boolean;
};
