import { AppRouteProps } from "@mzara/component";
import React from "react";
import { ProjectCalendarEditRoutes } from "./views/edit/ProjectCalendarEditRoutes";
import ProjectCalendarDetailsAppointmentContainer from "./container/ProjectCalendarDetailsAppointmentContainer";

export const ProjectCalendarCardDetailsRoutes: Array<AppRouteProps> = [
    {
        path: ":id",
        title: "{{AppointmentTitle}}",
        element: <ProjectCalendarDetailsAppointmentContainer />,
        roles: ["APPOINTMENT"],
        children: [
            ...ProjectCalendarEditRoutes
        ]
    },
];
