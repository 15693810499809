import { AppRouteProps } from "@mzara/component";
import React from "react";

const PlanningDetailContainer = React.lazy(
    () => import("./container/PlanningDetailContainer")
);

export const ProjectPlanningDetailRoutes: Array<AppRouteProps> = [
    {
        path: ":id",
        title: "{{PlanningDetailsTitle}}",
        element: <PlanningDetailContainer />,
        roles: ["APPOINTMENT"],
    },
];
