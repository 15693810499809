import { AppRouteProps } from "@mzara/component";
import React from "react";
import { CalendarPlanningContainer } from "./container/CalendarPlanningContainer";
import { CalendarPlanningListRoutes } from "./views/list/CalendarPlanningListRoutes";


export const CalendarPlanningRoutes: Array<AppRouteProps> = [
    {
        path: "planning",
        title: "Harea.Calendar.Planning",
        element: <CalendarPlanningContainer />,
        children: [
            ...CalendarPlanningListRoutes
        ]
    },
];
